import {
  Dialog,
  DialogFooter,
  DialogType,
  FontWeights,
  IButtonStyles,
  IconButton,
  IIconProps,
  Link,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import styles from "./Artifacts.module.scss";
import { trackPageView } from "../../../Services/AppInsights";
import moment from "moment";
import { IArtifactsJobResponse } from "../../../Models/API/IArtifactsJobResponse";
import { artifactsServiceContext } from "../../../Services/API/ArtifactsService";
import axios, { CancelTokenSource } from "axios";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import { AppCatalogServiceContext } from "../../../Services/AppCatalogService";
import ImportArtifactReport from "../../../Components/ImportArtifactReport/ImportArtifactReport";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const ArtifactsSettings: FC = () => {
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const artifactsService = useContext(artifactsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const appCatalogService = useContext(AppCatalogServiceContext);
  const theme = appCatalogService!.GetCurrentTheme();

  const [jobs, setJobs] = useState<IArtifactsJobResponse | null>(null);

  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const [downloading, setDownloading] = useState(false);
  const [downloadPercentage, setDownloadPercentage] = useState<number>(0);
  const [processing, setProcessing] = useState(false);

  const [importResult, setImportResult] = useState<string | null>(null);
  const [isImportResultDialogOpen, setIsImportResultDialogOpen] =
    useState(false);

  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "60%",
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.themePrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
        boxSizing: "border-box",
      },
    ],
    heading: {
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
      fontSize: "inherit",
      margin: "0",
    },
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "auto",
      boxSizing: "border-box",
      maxHeight: "80vh",
      width: "80vw",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  const loadJobs = async () => {
    var jobs = await artifactsService?.GetJobs();
    if (jobs) setJobs(jobs);
  };

  const downloadArtifact = async (chatAppId: number, blobId: string) => {
    setProcessing(true);
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    try {
      const result = await artifactsService!.DownloadArtifact(
        chatAppId,
        blobId,
        cancelTokenSource?.token,
        (progressEvent) => {
          const total = progressEvent.total || 1;
          const percentage = progressEvent.loaded / total;
          setDownloadPercentage(percentage);
        }
      );

      if (result) {
        const blob = new Blob([result.blob], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = result.filename;
        link.click();
      }
    } catch (error) {
      dialogService?.showWarningDialog(
        "Artifact Upload",
        "File extension not supported."
      );
    } finally {
      setProcessing(false);
      setDownloading(false);
      setDownloadPercentage(0);
      loadJobs();
    }
  };

  const openImportResultDialog = (result: string) => {
    setImportResult(result);
    setIsImportResultDialogOpen(true);
  };

  const closeImportResultDialog = () => {
    setIsImportResultDialogOpen(false);
    setImportResult(null);
  };

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelDownload = async () => {
    cancelTokenSource!.cancel("Operation canceled");
  };

  useEffect(() => {
    const execute = async () => {
      loadJobs();
      if (isMounted) {
        let intervalId = setInterval(loadJobs, 10_000);
        return () => {
          clearInterval(intervalId);
        };
      }
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (jobs && (
      <>
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: "100%" }}>
            <h2>Export Jobs</h2>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>From ChatApp</th>
                  <th>Libraries</th>
                  <th>Status</th>
                  <th>Started</th>
                  <th>Took</th>
                  <th>Error Message</th>
                  <th>Save Artifact</th>
                </tr>
              </thead>
              <tbody>
                {jobs?.exportJobs.map((job) => (
                  <tr key={job.id}>
                    <td>{job.from_chatapp_id}</td>
                    <td style={{ textWrap: "wrap", wordBreak: "break-word" }}>
                      {job.libraries.join(", ")}
                    </td>
                    <td>
                      <Stack
                        verticalAlign="center"
                        horizontal
                        tokens={{ childrenGap: 10 }}
                      >
                        <StackItem>{job.state}</StackItem>
                        <StackItem>
                          {job.end_time ? (
                            ""
                          ) : (
                            <Spinner
                              style={{ display: "inline-block" }}
                              size={SpinnerSize.small}
                            />
                          )}
                        </StackItem>
                      </Stack>
                    </td>
                    <td>{moment(job.start_time + "Z").fromNow()}</td>
                    <td>
                      {job.end_time
                        ? moment(job.end_time + "Z").from(
                            moment(job.start_time + "Z"),
                            true
                          )
                        : "Running..."}
                    </td>
                    <td style={{ textWrap: "wrap", wordBreak: "break-word" }}>
                      {job.error ? job.error : "N/A"}
                    </td>
                    <td>
                      {job.blob_id ? (
                        <PrimaryButton
                          onClick={() =>
                            downloadArtifact(job.from_chatapp_id, job.blob_id!)
                          }
                          text="Save as"
                          disabled={downloading} // Disable button while downloading
                        />
                      ) : (
                        <>{job.state !== "Succeeded" ? "N/A" : "Downloaded"}</>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: "100%" }}>
            <h2>Import Jobs</h2>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>To ChatApp</th>
                  <th>Status</th>
                  <th>Started</th>
                  <th>Took</th>
                  <th>Report</th>
                  <th>Error Message</th>
                </tr>
              </thead>
              <tbody>
                {jobs?.importJobs.map((job) => (
                  <tr key={job.id}>
                    <td>{job.to_chatapp_id}</td>
                    <td>
                      <Stack
                        verticalAlign="center"
                        horizontal
                        tokens={{ childrenGap: 10 }}
                      >
                        <StackItem>{job.state}</StackItem>
                        <StackItem>
                          {job.end_time ? (
                            ""
                          ) : (
                            <Spinner
                              style={{ display: "inline-block" }}
                              size={SpinnerSize.small}
                            />
                          )}
                        </StackItem>
                      </Stack>
                    </td>
                    <td>{moment(job.start_time + "Z").fromNow()}</td>
                    <td>
                      {job.end_time
                        ? moment(job.end_time + "Z").from(
                            moment(job.start_time + "Z"),
                            true
                          )
                        : "Running..."}
                    </td>
                    <td>
                      {job.import_result ? (
                        <Link
                          onClick={() =>
                            openImportResultDialog(job.import_result!)
                          }
                        >
                          View
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{job.error ? job.error : "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Stack.Item>
        </Stack>
        <Dialog
          hidden={!processing && downloadPercentage === 0}
          modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
          dialogContentProps={{
            showCloseButton: false,
            type: DialogType.normal,
            title: "Save Artifact",
          }}
        >
          {processing && <ProgressIndicator description="Processing..." />}
          {!processing && downloadPercentage > 0 && (
            <ProgressIndicator
              description="Downloading..."
              percentComplete={downloadPercentage}
            />
          )}
          <DialogFooter>
            {downloadPercentage < 1 && (
              <PrimaryButton
                disabled={downloadPercentage === 1}
                onClick={cancelDownload}
                text="Cancel"
              />
            )}
          </DialogFooter>
        </Dialog>

        <Modal
          isOpen={isImportResultDialogOpen}
          onDismiss={closeImportResultDialog}
          isBlocking={false}
        >
          <div className={contentStyles.header}>
            <h2 className={contentStyles.heading}>Migration report</h2>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={closeImportResultDialog}
            />
          </div>
          <div className={contentStyles.body}>
            <ImportArtifactReport reportResults={importResult} />
          </div>
        </Modal>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default ArtifactsSettings;
